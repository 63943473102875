import { useState } from 'react';
import Footer from "./Footer"
import { useNavigate } from 'react-router-dom';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';


import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setLocalStorage, getLocalStorage } from '../cryptoUtils';

const SMSVerification = () => {

    const [mobile, setMobile] = useState("");
    const [mobileError, setMobileError] = useState("");

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.familyAPI.scopes.read,
    });
    

    const handleMobileChange = (value) => {
        setMobile(value);
        setMobileError("");
    };

  
    const SendOtp = () => {

        if (!mobile) {
            setMobileError("Mobile number is required.");
            return;
        }
        if (mobile <7) {
            setMobileError("Please enter a valid mobile number");
            return;
        };

        setLoading(true);

        const EmailAddress = getLocalStorage('Username');
        const MobileNumber = mobile;
        const AuthenticationProvider = "Google";

        execute("POST", `https://drringfamily.azurewebsites.net/user?EmailAddress=${EmailAddress}&MobileNumber=${MobileNumber}&AuthenticationProvider=${AuthenticationProvider}`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setUserData(response.data);
                    setLoading(false);
                    setLocalStorage('mobile', MobileNumber);
                    alert("OTP Sent Successfully");
                    navigate("/OTP", { state: { EmailAddress: EmailAddress, MobileNumber: MobileNumber } }); // Navigate to OTP page upon successful API call
                } else {
                    setLoading(false);
                    console.error('Unexpected response:', response);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.error('Error fetching user data:', err);
            });

    };


  



    return (
        <>
     

                <div className="flex  min-h-screen min-w-screen bg-white">
                    <div className="flex w-full flex-col items-center mt-24">
                        <div className='mb-8'>
                            <img src={require('../img/DrringImg.png')} alt="durring" width="200px" height="131px" opacity="0px" />
                        </div>
                        <div className=" p-5 rounded-3xl shadow-md max-w-sm" style={{ backgroundColor: "#CFDAE6" }}>

                            <div className="text-center mb-9">
                                <h2 className="text-lg font-bold mb-2 text-gray-700">Please Enter Mobile For OTP</h2>
                            </div>
                            <div className="mb-7">
                                <PhoneInput
                                    country={'us'}
                                    placeholder='Mobile Number'
                                    value={mobile}
                                    autoComplete="off"
                                    onChange={handleMobileChange}
                                    inputStyle={{
                                        width: '100%',
                                        height: '3rem',
                                        fontSize: '0.875rem',
                                        paddingLeft: '2.5rem',
                                        border: "none",
                                        borderRadius: '0.375rem',
                                        outline: 'none',
                                    }}
                                    containerStyle={{
                                        width: '100%'
                                    }}
                                    buttonStyle={{
                                        border: "none",
                                        borderRadius: ' 0.375rem 0 0 0.375rem',
                                    }} inputProps={{
                                        name: 'mobile',
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    className='no-border-input' 
                                />
                                 
                                {mobileError && (
                                    <p className="text-xs mt-2 w-full text-red-500 text-center font-medium">{mobileError}</p>
                                )}
                            </div>

                            <div className="w-full flex justify-center items-center mb-2">
                                <button
                                    style={{ backgroundColor: "#FF186B" }}
                                    onClick={SendOtp}
                                    className="w-3/4 text-white py-2 rounded-md font-semibold"
                                >Send OTP</button>
                            </div>
                        </div>

                        <Footer />
                    </div>


                </div>
        
        </>
    );
};

export default SMSVerification;