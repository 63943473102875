import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};


const Map = ({ isLiveTracking, map }) => {
  const [currentPosition, setCurrentPosition] = useState(null);

  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) =>
            console.error(error)
          ,
          { enableHighAccuracy: true }
        );
      }
    };
    
    if (!map ) {
      getCurrentLocation();
    } else {
      setCurrentPosition({
        lat: map.latitude,
        lng: map.longitude,
      });
    }

    let watchId;
    if (isLiveTracking && navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => console.error(error),
        { enableHighAccuracy: true }
      );
    }
    
    return () => {
      if (navigator.geolocation && watchId !== undefined) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [isLiveTracking, map]);

  if (!currentPosition) {
    return <div className='flex justify-center pt-36 h-40'>Loading map...</div>;
  }

  if ( map == "null") {
    return <div className='flex justify-center pt-36 h-40'>Location not found...</div>;
  }
  return (
  <LoadScript googleMapsApiKey="AIzaSyCA6sRTSRRNjZFwPN4mlTk9wAPcSegM4fI">
      <GoogleMap mapContainerStyle={containerStyle} center={currentPosition} zoom={14}>
        <div className='w-4 h-4 bg-red-800'><Marker  position={currentPosition} /></div>        
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;

