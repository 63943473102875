import React, { useState } from 'react'
import Modal from './Modal';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';


const Footer = () => {
    const [modalContent, setModalContent] = useState({ isOpen: false, title: '', ContentComponent: null });

    const openModal = (title, ContentComponent) => {
        setModalContent({ isOpen: true, title, ContentComponent });
    };

    const closeModal = () => {
        setModalContent({ isOpen: false, title: '', ContentComponent: null });
    };

    return (
      <>
        <footer className="w-full relative bottom-0">
          <div className="lg:mx-20 mx-12 boder bordertop"></div>
          <div className="bg-white lg:flex md:justify-between  px-7  py-3  text-center text-sm ">
            <div className="flex justify-center mb-1">
              <div className="ml-4 justify-center">
                <a
                  href="https://drringhealth.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../img/DrringImg.png")}
                    alt="Drring Health"
                    width="90px"
                    height="60px"
                    opacity="0px"
                  />
                </a>
              </div>
            </div>
            <div className="font-semibold mt-2 text-sm pl-1">
              {" "}
              &copy; Copyright 2024 Drring Health Private Limited |
              <a
                href="#"
                className="font-semibold no-underline text-black pl-1"
                onClick={(e) => {
                  e.preventDefault();
                  openModal("Terms of Use", TermsOfUse);
                }}
              >
                Terms of Use |
              </a>
              <a
                href="#"
                className="font-semibold no-underline text-black  pl-1"
                onClick={(e) => {
                  e.preventDefault();
                  openModal("Privacy Policy", PrivacyPolicy);
                }}
              >
                Privacy Policy
              </a>
            </div>
            <div className="flex justify-center lg:justify-center lg:mt-0 mt-2 space-x-4 mr-4 ">
              <a
                href="https://www.facebook.com/drring.health"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/ios-filled/24/000000/facebook--v1.png"
                  alt="Facebook"
                  className="rounded-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/drring-health/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/ios-filled/24/000000/linkedin.png"
                  alt="LinkedIn"
                  className="rounded-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@drringhealth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/ios-filled/24/000000/youtube-play.png"
                  alt="YouTube"
                  className="rounded-full"
                />
              </a>
              <a
                href="https://www.instagram.com/drring.health/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/ios-filled/24/000000/instagram-new.png"
                  alt="Instagram"
                  className="rounded-full"
                />
              </a>
            </div>
          </div>
        </footer>
        <Modal
          isOpen={modalContent.isOpen}
          onClose={closeModal}
          title={modalContent.title}
          ContentComponent={modalContent.ContentComponent}
        />
      </>
    );
}

export default Footer;
