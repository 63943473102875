import { useState, useEffect, useCallback } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Map from "./Map"

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';

import { getLocalStorage } from '../cryptoUtils';


const generateTimeOptions = () => {
  const options = [];
  for (let hour = 1; hour <= 12; hour++) {
    const hourString = hour.toString().padStart(2, '0');
    options.push({ display: `${hourString}:00 AM`, value: `${hourString}:00` });
    if (hour !== 12) {
      const pmHour = (hour + 12).toString().padStart(2, '0');
      options.push({ display: `${hourString}:00 PM`, value: `${pmHour}:00` });
    }
  }
  options.push({ display: '12:00 PM', value: '12:00' });
  // options.push({ display: '12:00 AM', value: '00:00' });
  return options;
};

const getNearestHour = () => {
  const now = new Date();
  let hour = now.getHours();
  hour = hour % 24;
  return hour.toString().padStart(2, '0') + ':00';
};

const generateDateOptions = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth(); // 0-indexed month
  const currentDate = now.getDate();

  const options = [];
  for (let date = 1; date <= currentDate; date++) {
    const fullDate = new Date(currentYear, currentMonth, date);
    const displayDate = fullDate.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).replace(',', '');

    const day = fullDate.getDate();
    const suffix = day === 1 || day === 21 || day === 31 ? 'st' : (day === 2 || day === 22 ? 'nd' : (day === 3 || day === 23 ? 'rd' : 'th'));
    const display = `${day}${suffix} ${fullDate.toLocaleDateString('en-US', { month: 'long' })} ${currentYear}`;
    const valueDate = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
    options.push({ display, value: valueDate });
  }

  return options;
};


const getCurrentDateValue = () => {
  const now = new Date();
  const yesterday = new Date(now);
  return yesterday.toISOString().split('T')[0];
};

const Tracking = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLiveTracking, setIsLiveTracking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [count, setCount] = useState(-1);
  const [map, setMap] = useState(null)

  const [loction, setLoction] = useState(null)
  const [selectedTime, setSelectedTime] = useState(getNearestHour());
  const [selectedDate, setSelectedDate] = useState(getCurrentDateValue());
  const [selectedIndex, setSelectedIndex] = useState(null);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleToggleChange = () => {
    setIsLiveTracking(!isLiveTracking);
  };

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.familyAPI.scopes.read,
  });


  const handleFetchUserData = useCallback(() => {
    const mobile = getLocalStorage('mobile');
    setLoading(true);

    execute("GET", `https://drringfamily.azurewebsites.net/family/${mobile}`)
      .then((response) => {
        if (response.status === 404) {
          setData(0);
        } else {
          const macAddress = response[0]?.macAddress;
          setSelectedValue(macAddress);
          setData(response);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => setLoading(false));
  }, [execute]);

  useEffect(() => {
    handleFetchUserData();
  }, [handleFetchUserData]);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setSelectedDate(getCurrentDateValue())
    setSelectedTime(getNearestHour())
  };

  const fetchTracking = () => {
    setLoading(true);
    execute("GET", `https://drringfamily.azurewebsites.net/locations/${selectedValue}`)
      .then((response) => {
        setLoading(false);
        if (response.status === 404) {
          setMap(null);
        } else {
          setMap(response);
          setLoction(response[0]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching tracking data:", err);
      });
  };

  useEffect(() => {
    if (selectedValue) {
      fetchTracking();
    }
  }, [selectedValue]);


  const timeOptions = generateTimeOptions();

  const handleChangeTime = (event) => {
    setSelectedTime(event.target.value);
  };

  const dateOptions = generateDateOptions();

  const handleChangeDate = (event) => {
    const selectedDate = (event.target.value);
    setSelectedDate(selectedDate);
  };


  const fetchLoction = () => {
    setLoading(true);
    execute("GET", `https://drringfamily.azurewebsites.net/location/${selectedValue}?date=${selectedDate}&time=${selectedTime}`)
      .then((response) => {
        setLoading(false);
        if (response.status === 404) {
          setLoction(null);
        } else {
          setLoction(response);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching location data:", err);
      });
  };

  useEffect(() => {
    if (selectedValue && selectedDate && selectedTime) {
      fetchLoction();
    }
  }, [selectedValue, selectedDate, selectedTime]);




  const handleItemClick = (index, item) => {
    setLoction(item);
    setSelectedIndex(index)
  };

  return (


    <div className="flex flex-col h-screen">
      <div className="w-full h-16">
        <Navbar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
      </div>

      <div className=" w-full h-90vh">
        <div className={`${isSidebarOpen ? 'block' : 'hidden'} z-10 absolute hiddenSidebarTrack `} >
          <Sidebar
            activePage="Tracking"
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className="flex flex-row h-97 ">
          <div className="hidden sm:block  mt-2px">
            <Sidebar activePage="Tracking" />
          </div>


          <div className="p-6 w-full h-full">
            <div className='flex flex-col gap-3 lg:flex-row w-full'>
              <div className='flex flex-row h-10 md:h-10 gap-3' >
                <select
                  value={selectedDate}
                  onChange={handleChangeDate}
                  className='px-2 h-full rounded border w-36 aftab'
                >
                  {dateOptions.map((date, index) => (
                    <option className="h-full w-full" key={index} value={date.value}>{date.display}</option>
                  ))}
                </select>

                <select
                  value={selectedTime}
                  onChange={handleChangeTime}
                  className='px-4 h-full rounded border w-36'
                >
                  {timeOptions.map((time, index) => (
                    <option key={index} value={time.value}>{time.display}</option>
                  ))}
                </select>
              </div>

              <div className='flex flex-row h-10'>
                <select
                  className='px-1 py-2 h-10 md:h-full rounded border w-36'
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {data === 0 ? (
                    <option value="" disabled>Data not found</option>
                  ) : (
                    data.map((user, index) => (
                      <option key={index} value={user.macAddress}>
                        {user.userName} ({user.relation})
                      </option>
                    ))
                  )}
                </select>

                {/* <div className='flex flex-row px-4 gap-2'>
                  <p className='h-full p-2 lg:pt-2 text-sm md:text-base'>Enable Live Tracking</p>
                  <label for="toggle" className="flex items-center cursor-pointer">
                    <div className="relative">
                      <input
                        id="toggle"
                        type="checkbox"
                        className="sr-only"
                        checked={isLiveTracking}
                        onChange={handleToggleChange}
                      />
                      <div className="block bg-gray-500 w-10 h-6 rounded-full"></div>
                      <div className="dot absolute left-1 right-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>
                  </label>
                </div> */}
              </div>
            </div>

            <div className='w-full h-full sm:h-5/6 md:h-full flex  flex-col md:flex-row xl:gap-16  gap-4 ' >
              <div className='w-full  h-3/6  sm:h-2/5  lg:h-5/6 lg:w-3/5 mt-6 md:h-4/6' >
                <Map isLiveTracking={isLiveTracking} map={loction} />
              </div>

              <div className='lg:w-1/4 lg:h-3/4 h-3/6 lg:mt-16	md:h-3/5 md:w-2/5 mb-6 md:mt-10 ' >
                <div className='w-full  items-center p-2 font-semibold text-md flex justify-center HeadingPre'>
                  Previous known locations
                </div>

                <div id="MapData" className='w-full h-5/6 border'>
                  {map == null ? (
                    <div className='flex justify-center mt-20'>Data not found...</div>
                  ) : (
                    <>
                      {map.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleItemClick(index, item)}
                          className="w-full flex flex-col border-none justify-between"
                          style={{ backgroundColor: item.geofence === "in" ? "#E8DDDD" : "#F7F7F7", cursor: 'pointer', }}
                        >
                          <h6 className='p-2 pt-3   text-xs font-medium'>{item.date} {item.time}</h6>
                          <p className='p-2 pt-1  text-xs font-normal text-gray-900'>{item.address}</p>
                        </div>
                      ))}
                    </>
                  )}

                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full justify-center flex mb-5 lg:mb-0 xl:mb-0 '>
          <Footer />
        </div>
      </div>

    </div>

  );
};

export default Tracking;