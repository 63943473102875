import React, { useState, useEffect } from 'react';
import Footer from "./Footer"
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';

const OTPVerification = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { EmailAddress } = location.state;
  const { MobileNumber } = location.state;

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.familyAPI.scopes.read,
  });

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [countdown, setCountdown] = useState(40);
  const [otp, setOtp] = useState(['', '', '', '', '']);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };


  const verifyOTP = () => {
    const OTP = otp.join('');
    execute("POST", `https://drringfamily.azurewebsites.net/user/verify?EmailAddress=${EmailAddress}&MobileNumber=${MobileNumber}&OTP=${OTP}`)
      .then((data) => {
        if (data.status === 400) {
          alert("Please enter OTP");
        }
        if (data.status === 404) {
          alert("Please enter valid OTP");
          setOtp(['', '', '', '', ''])
        }
        if (data.status !== 404 && data.status !== 400) {
          setLoading(false);
          //alert("Login Successfully");
          navigate("/Home",);
        } else {
          setLoading(false);
          console.error('Unexpected response:', data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error fetching user data:', err);
      });


  }


  const ResendOtp = () => {
    const AuthenticationProvider = "Google";
    execute("POST", `https://drringfamily.azurewebsites.net/user?EmailAddress=${EmailAddress}&MobileNumber=${MobileNumber}&AuthenticationProvider=${AuthenticationProvider}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          setDisabled(true);
          setCountdown(90);
          alert("Resend OTP Successfully");
        } else {
          setLoading(false);
          console.error('Unexpected response:', response);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error fetching user data:', err);
      });
  }


  useEffect(() => {
    let timer = null;
    if (disabled) {
      timer = setTimeout(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearTimeout(timer);
      setDisabled(false);
      setCountdown(40);
    }

    return () => clearTimeout(timer);
  }, [countdown, disabled]);

  return (
    <div className="flex  min-h-screen min-w-screen bg-white">
      <div className="flex w-full flex-col items-center mt-24">
        <div className='mb-8'>
          <img src={require('../img/DrringImg.png')} alt="durring" width="200px" height="131px" opacity="0px" />
        </div>
        <div className=" p-5 rounded-3xl shadow-md max-w-sm" style={{ backgroundColor: "#CFDAE6" }}>

          <div className="text-center mb-4">
            <h2 className="text-xl font-bold mb-2 text-gray-700">Please Enter OTP</h2>
            <p className="text-xs font-semibold  text-gray-800">Enter the OTP received as an invitation</p>
          </div>
          <div className="flex justify-between gap-2 mb-4">
            {otp.map((data, index) => {
              return (
                <input
                  className="w-8 h-12 text-xl text-center border rounded-md focus:outline-none focus:border-pink-500"
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={e => handleChange(e.target, index)}
                  onFocus={e => e.target.select()}
                />
              );
            })}
          </div>
          <div className="w-full flex justify-center items-center mb-2">
            <button
              style={{ backgroundColor: "#FF186B" }}
              onClick={verifyOTP}
              className="w-3/4 text-white py-2 rounded-md font-semibold"
            >Verify OTP</button>
          </div>

          <div className='flex justify-end'>
            <button
              onClick={ResendOtp}
              className={`text-xs font-medium h-6 mt-2 ${disabled ? 'text-black cursor-not-allowed' : 'bg-black text-white cursor w-16 rounded-md'}`}
              disabled={disabled}
            >
              {disabled ? `Resend OTP in ${countdown} Seconds` : 'Resend'}
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OTPVerification;
