import { useState, useEffect, useCallback } from 'react';
import Chart from './Chart';
import Circle from './Circle';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { BiTimer } from "react-icons/bi";
import { IoFootsteps } from "react-icons/io5";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';
import { getLocalStorage } from '../cryptoUtils';

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Today');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [time, setTime] = useState(1);
  const [steps, setSteps] = useState(0);
  const [goalSteps, setGoalSteps] = useState(1000);
  const [activeMinutes, setActiveMinutes] = useState(0);
  const [goalActiveMinutes, setGoalActiveMinutes] = useState(300);

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.familyAPI.scopes.read,
  });

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  const handleFetchUserData = useCallback(() => {
    const mobile = getLocalStorage('mobile');
    setLoading(true);

    execute("GET", `https://drringfamily.azurewebsites.net/family/${mobile}`)
      .then((response) => {
        if (response.status === 404) {
          setData(0);
        } else {
          const macAddress = response[0]?.macAddress;
          setSelectedValue(macAddress);
          setData(response);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => setLoading(false));
  }, [execute]);

  useEffect(() => {
    handleFetchUserData();
  }, [handleFetchUserData]);

  const handleChange = useCallback((event) => {
    setSelectedValue(event.target.value);
    setSelectedItem("Today");
    setTime(1);
    setSteps(0);
    setActiveMinutes(0);
  }, []);

  const handleItemClick = useCallback((item) => {
    setSelectedItem(item);
    setSteps(0);
    setActiveMinutes(0);

    let timeValue, calculatedVal, calculatedSteps;
    switch (item) {
      case 'Today':
      case 'Yesterday':
        timeValue = item === 'Today' ? 1 : 2;
        calculatedVal = 300;
        calculatedSteps = 10000;
        break;
      case 'Week':
        timeValue = 3;
        const daysInWeek = 7;
        calculatedVal = 300 * daysInWeek;
        calculatedSteps = 10000 * daysInWeek;
        break;
      case 'Month':
        timeValue = 4;
        const currentMonthDays = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
        calculatedVal = 300 * currentMonthDays;
        calculatedSteps = 10000 * currentMonthDays;
        break;
      default:
        timeValue = 1;
        calculatedVal = 300;
        calculatedSteps = 10000;
    }

    setTime(timeValue);
    setGoalActiveMinutes(calculatedVal);
    setGoalSteps(calculatedSteps);
  }, []);

  const fetchGraphData = useCallback(() => {
    setLoading(true);

    execute("GET", `https://drringfamily.azurewebsites.net/activity/${selectedValue}?interval=${time}`)
      .then((response) => {
        if (response.status === 404) {
          setGraphData(0);
        } else {
          setGraphData(response);
          const totalSteps = response.reduce((sum, item) => sum + item.steps, 0);
          const activeMinutes = response.reduce((sum, item) => sum + item.activeMinutes, 0);
          setSteps(totalSteps);
          setActiveMinutes(activeMinutes);
        }
      })
      .catch((err) => {
        console.error("Error fetching graph data:", err);
      })
      .finally(() => setLoading(false));
  }, [execute, selectedValue, time]);

  useEffect(() => {
    if (selectedValue) {
      fetchGraphData();
    }
  }, [selectedValue, time, fetchGraphData]);

  return (
    <div className="flex flex-col h-screen ">
      <div className="w-full h-16">
        <Navbar
          activePage="Home"
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
      </div>

      <div className=" w-full" >
        <div className={`${isSidebarOpen ? 'block' : 'hidden'} z-10 absolute hiddenSidebar `}>
          <Sidebar
            activePage="Home"
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className=" flex flex-row h-87vh">
          <div className=" hidden sm:block mt-2px " >
            <Sidebar activePage="Home" />
          </div>



          <div className='max-h-full w-full '>
            <div className="flex w-full flex-col p-2 md:p-4  sm:max-h-full" >
              <div className=' flex flex-col md:flex-row w-full justify-around mt-6 px-3 md:px-8 text-sm lg:text-md'>
                <ul className='flex w-auto sm:w-96 mt-2  border flex-row justify-around h-10 px-2 md:px-1 xl:px4 py-2 bg-gray-50 rounded-lg'>
                  {['Today', 'Yesterday', 'Week', 'Month'].map((item) => (
                    <li
                      key={item}
                      className={`items-center  rounded cursor-pointer w-auto px-3 sm:px-5 md:px-5 lg:px-5 xl:px-5 2xl:px-6 ${selectedItem === item ? 'bg-gray-200 border' : 'bg-gray-50'
                        }`}
                      onClick={() => handleItemClick(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>

                <div className='w-28 lg:w-36 xl:w-56 mt-2 md:mt-0'>
                  <select
                    className="w-full px-1 h-10 border rounded-lg"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    {data === 0 ? (
                      <option value="" disabled>Data not found</option>
                    ) : (
                      data.map((user, index) => (
                        <option key={index} value={user.macAddress}>
                          {user.userName} ({user.relation})
                        </option>
                      ))
                    )}
                  </select>
                </div>

              </div>


              <div className=' w-full sm:w-4/5 m-auto sm:p-2  h-60 xl:h-full lg:h-80 mt-2 '>
                <Chart data={graphData} steps={steps} day={selectedItem} />
              </div>

              <div className=" flex flex-col w-auto xl:h-56 sm:flex-row max-h-full justify-around mx-12 lg:mx-48 sm:mx-12 md:mx-12 mt-3 sm:h-40 xl:mt-20">
                <Circle label="Active Minutes" value={activeMinutes} goal={goalActiveMinutes} icon={<BiTimer className=' text text-orange-600 text-xl' />} />
                <Circle label="Steps" value={steps} goal={goalSteps} icon={<IoFootsteps className=' text text-blue-600 text-xl' />} />
              </div>
            </div>
          </div>
        </div>

        <div className='w-full justify-center flex  lg:mb-0 xl:mb-0  '>
          <Footer />
        </div>
      </div>

    </div>



  );
};

export default Home;