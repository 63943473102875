import { useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Settings = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="w-full h-16">
        <Navbar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
      </div>

      <div className="w-full h-90vh">
        <div className={`${isSidebarOpen ? 'block' : 'hidden'} z-10 absolute hiddenSidebarTrack`}>
          <Sidebar
            activePage="Settings"
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className="flex flex-row h-87vh">
          <div className="hidden sm:block  mt-2px ">
            <Sidebar activePage="Settings" />
          </div>

          <div className='h-full flex items-center justify-center w-full'>
            <div className='text-center w-52 m-auto'>
              <div className=''>
                <img
                  className="m-auto"
                  src={require('../img/DrringImg.png')}
                  alt="durring"
                  width="200px"
                  height="130px"
                />
              </div>
              <h3 className='w-full text-bold flex justify-center mt-3'>Coming Soon...</h3>
            </div>
          </div>

        </div>

        <div className='w-full justify-center flex '>
        <footer className="w-full absolute" >
            <div className="lg:mx-20 mx-12 boder bordertop "></div>
            <div className='bg-white lg:flex md:justify-between  px-7  py-3  text-center text-sm '>
              <div className='flex justify-center mb-1'>
                <div className='ml-4 justify-center'><img src={require('../img/DrringFamily.png')} alt="durring" width="80px" height="50px" opacity="0px" /></div>
              </div>
              <div className="font-semibold mt-2 text-sm pl-1"> &copy; Copyright 2024 Drring Health Private Limited |
                <a href="#" className="font-semibold no-underline text-black  pl-1">Terms of Use</a>
                <a href="#" className="font-semibold no-underline text-black  pl-1">Privacy Policy</a>
              </div>
              <div className="flex justify-center lg:justify-center lg:mt-0 mt-2 space-x-4 mr-4 ">
                <a href="#"><img src="https://img.icons8.com/ios-filled/24/000000/facebook--v1.png" alt="Facebook" className="rounded-full" /></a>
                <a href="#"><img src="https://img.icons8.com/ios-filled/24/000000/linkedin.png" alt="LinkedIn" className="rounded-full" /></a>
                <a href="#"><img src="https://img.icons8.com/ios-filled/24/000000/youtube-play.png" alt="YouTube" className="rounded-full" /></a>
                <a href="#"><img src="https://img.icons8.com/ios-filled/24/000000/instagram-new.png" alt="Instagram" className="rounded-full" /></a>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>

  )
}

export default Settings