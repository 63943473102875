import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import SignIn from './components/SignIn';
import Mobile from "./components/SMSVerification"
import OTP from "./components/OTPVerification"
import Home from "./components/Home"
import Tracking from "./components/Tracking"
import Settings from "./components/Settings"
import "./index.css"
import "./Style.css"



const Pages = () => {  
  return (
    <Routes>
      <Route path='/' element={<SignIn />} />
      <Route path='/SMSVerification' element={<Mobile />} />
      <Route path='/OTP' element={<OTP />} />
      <Route path='/Home' element={<Home />} />
      <Route path='/Tracking' element={<Tracking />} />
      <Route path='/Settings' element={<Settings />} />
    </Routes>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
        <Pages />
    </MsalProvider>
  );
};

export default App;
