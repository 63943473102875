import React from 'react';


const Circle = ({ label, value, goal,icon }) => {
  return (
    <div className="flex flex-col items-center p-4 max-h-full">
      
        
      <div className="text-gray-600 justify-center text-center w-full flex space-x-1">{icon}<p className='pl-1'></p>{label}</div>
      
      
      <div className="relative w-24 h-24 xl:w-48 xl:h-48  ">
        <svg className="w-full h-full">
          <circle
            className="text-gray-300"
            strokeWidth="4"
            stroke="currentColor"
            fill="transparent"
            r="40"
            cx="50%"
            cy="50%"
          />
          <circle
            className="text-blue-600"
            strokeWidth="4"
            strokeDasharray={251.2}
            strokeDashoffset={251.2 - (value / goal) * 251.2}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r="40"
            cx="50%"
            cy="50%"
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-xl">{value}</span>
        </div>
      </div>

      {/* <div className="text-gray-600">{goal}</div> */}
      
    </div>
  );
};

export default Circle;