import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import moment from 'moment';

const Chart = ({ data, day }) => {
  if (!data) {
    return <div className='pt-4 lg:w-3/5 mx-auto flex justify-center h-full  w-96 lg:h-80 xl:96'><p className='m-auto '>data not found...</p></div>;
  }

  const stepsByDateTime = data.map(item => ({
    steps: item.steps,
    dateTime: item.dateTime,
  }));

  const Data = stepsByDateTime.reduce((acc, item) => {
    const day = moment(item.dateTime).format('ddd');
    const hour = moment(item.dateTime).hour();
    if (!acc[day]) {
      acc[day] = Array.from({ length: 24 }, () => 0);
    }
    acc[day][hour] += item.steps;
    return acc;
  }, {});

  let chartData;

  if (day === "Today" || day === "Yesterday") {
    const allDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const hourLabels = Array.from({ length: 24 }, (_, i) => moment().startOf('day').hour(i).format('h A'));
    const datasets = Object.keys(Data).map((day, index) => ({
      label: day,
      data: Data[day] || Array.from({ length: 24 }, () => 0),
      backgroundColor: `rgba(54, 162, 235, ${0.6 - index * 0.1})`, 
    }));

    chartData = {
      labels: hourLabels,
      datasets: datasets,
    };
  } else if (day === "Month") {
    const startDate = moment().subtract(29, 'days').startOf('day');
    const last30DaysData = stepsByDateTime.filter(item => moment(item.dateTime).isSameOrAfter(startDate));

    const transformedData = last30DaysData.reduce((acc, item) => {
      const date = moment(item.dateTime).format('DD-MM-YYYY');
      acc[date] = (acc[date] || 0) + item.steps;
      return acc;
    }, {});

    const labels = Array.from({ length: 30 }, (_, i) => startDate.clone().add(i, 'days').format('DD-MM-YYYY'));
    const stepsData = labels.map(date => transformedData[date] || 0);

    chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Steps in the Last 30 Days',
          data: stepsData,
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
        },
      ],
    };
  } else {
    const allDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const transformedData = data.reduce((acc, item) => {
      const day = moment(item.dateTime).format('ddd');
      acc[day] = (acc[day] || 0) + item.steps;
      return acc;
    }, {});

    const labels = allDays;
    const stepsData = labels.map(day => transformedData[day] || 0);

    chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Total Steps in this Week',
          data: stepsData,
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
        },
      ],
    };
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="pt-4 lg:w-3/5 mx-auto flex justify-center h-full">
      <Bar data={chartData} options={options} className='mx-4 w-96 h-full lg:h-80 xl:96 ' />
    </div>
  );
};

export default Chart;