import React from 'react';

const PrivacyPolicy = () => {
   return (
     <div>
       <p>
         <strong>Last updated on</strong>: July 23, 2024
       </p>
       <p>
         <strong>GENERAL</strong>
       </p>
       <p>
         {" "}
         PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING THIS WEBSITE OR OUR
         SERVICES YOU ACCEPT ALL TERMS AND CONDITIONS IN THIS POLICY. <br />
         <br /> This Privacy Policy (the “Agreement”) governs your use of this
         website, www.drring.health and www.drringhealth.com (the “Website”),
         DRRING HEALTH PRIVATE LIMITED (“Business Name”), referred in this
         policy agreement (the “Agreement”) as “Drring Health”.
       </p>
       <p>
         <b>a.</b> This Website with the URL of&nbsp;
         <a
           href="https://family.drring.health"
           target="_blank"
           rel="noopener noreferrer"
         >
           https://family.drring.health
         </a>{" "}
         and&nbsp;
         <a href="https://drringhealth.com">https://drringhealth.com</a>{" "}
         (“Website/Site”) is operated by DRRING HEALTH PRIVATE LIMITED
         (“We/Our/Us”). We are committed to protecting and respecting your
         privacy.
       </p>
       <p>
         {" "}
         When utilizing the DRRING ALERT, DRRING PROTECT, or DRRING mobile
         application(s), we gather location data from the application. This data
         is exclusively employed in the event of an emergency or if you activate
         the SOS button, and it is shared solely with your designated Next of
         Kin, helpers and our command centre. The purpose is to enable them to
         locate you and provide assistance. Under no circumstances is this
         location information disclosed to any external entities unrelated to
         the DRRING HEALTH platform, and it is not used for any purposes
         unrelated to emergencies or the aforementioned SOS event.{" "}
       </p>
       <p>
         <b>b.</b> We collect your personal information in order to provide and
         continually improve our products and services.
       </p>
       <p>
         <b>c.</b> Our privacy policy is subject to change from time to time. We
         request you to please review this policy periodically.
       </p>
       <p>
         <b>d.</b> All partner firms and any third-party working with or for Us,
         and who have access to personal information, will be expected to read
         and comply with this policy.
       </p>
       <p>
         <strong>HOW WE COLLECT THE INFORMATION</strong>
       </p>
       <p>
         <b>a.</b> From you directly and through this Site: We may collect
         information through the Website when you visit. The data we collect
         depends on the context of your interactions with our Website.
       </p>
       <p>
         <b>b.</b> Through business interaction: We may collect information
         through business interaction with you or your employees.
       </p>

       <p>
         <strong>INFORMATION WE COLLECT WITH YOUR EXPLICIT CONSENT</strong>
       </p>
       <p>
         <b>a.</b> Your Next of Kin's mobile number which you feed in.
       </p>
       <p>
         <b>b.</b> Your location information when you press the SOS button or in
         an emergency.
       </p>
       <p>
         <b>c.</b> We will retain your information as long as we require this to
         provide you with the goods and services and for such a period as
         mandated by the laws concerned.
       </p>
       <p>
         <b>d.</b> If you opt to receive marketing correspondence from us,
         subscribe to our mailing list or newsletters, enter into any of our
         competitions or provide us with your details in order to provide you
         with details about our goods, services, business updates and events.
       </p>
       <p>
         <b>e.</b> Our app requires SMS/Call Log permissions to perform its core
         functionality of emergency alert notifications. When the SOS button on
         our associated life-saving alert device is pressed, the app uses these
         permissions to call your pre-configured emergency contact numbers. The
         app will sequentially call each number until contact is made, ensuring
         that emergency assistance is reached as quickly as possible. We
         strictly use these permissions for the purpose of emergency
         communication only. We do not sell or share your personal or sensitive
         data for any other purposes. Users are informed and consent is obtained
         for the use of these permissions within the app.
       </p>
       <p>
         <strong>HOW TO WITHDRAW YOUR CONSENT</strong>
       </p>
       <p>
         If after you opt-in, you change your mind, you may withdraw your
         consent for us to contact you, for the continued collection, use or
         disclosure of your information, at anytime, by contacting us by email
         at <a href="mailto:ring@drring.health">ring@drring.health</a>. You must
         include your email address and your phone number in that communication
         so that we can trace your personal information.
       </p>
       <p>
         <strong>HOW WE USE INFORMATION</strong>
       </p>
       <p>
         <b>a.</b> We use the information we collect primarily to provide,
         maintain, protect, and improve our current products and services.
       </p>
       <p>
         <b>b.</b> We use the information collected through this website to
         deliver service to you directly or to send your location information to
         your designated Next of Kin or caregivers.
       </p>
       <p>
         <b>c.</b> Improve our services, Site and how we operate our businesses.
       </p>
       <p>
         <b>d.</b> Protect, investigate, and deter against fraudulent,
         unauthorized, or illegal activity.
       </p>
       <p>
         <strong>DATA TRANSFER</strong>
       </p>
       <p>
         <b>a.</b> Information about our users is an important part of our
         business and we take due care to protect the same.
       </p>
       <p>
         <b>b.</b> We share your data with your consent to complete any
         transaction or provide any product or service you have requested or
         authorized.
       </p>
       <p>
         <strong>DATA SECURITY</strong>
       </p>
       <p>
         <b>a.</b> We take due care to protect customer data. Technical measures
         are in place to prevent unauthorized or unlawful access to data and
         against accidental loss or destruction of, or damage to, data.{" "}
       </p>
       <p>
         <strong>LINKS TO THIRD PARTY SITE/APPS</strong>
       </p>
       <p>
         Our service may, if required by you, provide you third party care
         givers and helpers. Please note that if you use their service we do not
         accept any responsibility or liability for the data you may have
         provided them.
       </p>

       <p>
         <strong>THIRD PARTY TRACKING AND AD SERVING DISCLOSURE</strong>
       </p>
       <p>
         Our privacy policy ensures transparency regarding the use of
         third-party cookies and web beacons on our website. Users are informed
         that third parties may place and read cookies on their browsers or
         utilize web beacons to gather information through ad serving. This
         enables us to tailor advertising content and enhance user experience
         while ensuring user privacy remains a top priority. We are committed to
         providing our users with clear and comprehensive information about the
         data collection practices on our website.
       </p>
       <p>
         <strong>SHARING OF PERSONAL INFORMATION</strong>
       </p>
       <p>
         We do not share your personal data with third parties without your
         prior consent.
       </p>
       <p>
         <strong>AGE OF CONSENT</strong>
       </p>
       <p>
         By accessing this website, you confirm that you meet the age
         requirement stipulated by the laws of your state or province of
         residence, or that you not only meet this age requirement but also have
         provided your consent for any minor dependents under your care to use
         this website.
       </p>
       <p>
         <strong>CHANGES TO THIS POLICY</strong>
       </p>
       <p>
         We retain the authority to amend this privacy policy as necessary, and
         we encourage you to periodically review it. Any modifications or
         clarifications will become effective immediately upon publication on
         the website. In the event of substantial changes to this policy, we
         will provide notification on this platform to inform you of the
         updates. This way, you can stay informed about the information we
         collect, how we utilize it, and the circumstances, if any, under which
         we share or disclose it.
       </p>
       <p>
         Furthermore, if our store is acquired or amalgamated with another
         company, your information may be transferred to the new owners to
         ensure the continued provision of our products and services to you.
       </p>
       <p>
         <strong>NEWSLETTER</strong>
       </p>
       <p>
         <b>a.</b> Following subscription to the newsletter, your e-mail address
         is used for our advertising purposes until you cancel the newsletter
         again. Cancellation is possible at any time. The following consent has
         been expressly granted by you separately, or possibly in the course of
         an ordering process: (I am accepting to receive a newsletter from this
         website), you may revoke your consent at any time with future effect.
         If you no longer want to receive the newsletter, then unsubscribe by
         clicking on the unsubscribe option given in the email footer.
       </p>
       <p>
         If you have any concerns about privacy or grievances with Us, please
         contact us with a thorough description and we will try to resolve the
         issue for you.
       </p>
       <p>
         <strong>Contact Details:</strong>
       </p>
       <p>
         DRRING HEALTH PRIVATE LIMITED <br />
         120, Village Patparganj, <br />
         Delhi, Delhi 110019 <br />
         Email: <a href="mailto:ring@drring.health">ring@drring.health</a>
       </p>
     </div>
   );
};

export default PrivacyPolicy;
