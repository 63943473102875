// Modal.js
import React from 'react';

function Modal({ isOpen, onClose, title, ContentComponent }) {
  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto"
      onClick={handleOutsideClick}
    >
      <div className="bg-white p-6 rounded-lg max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <a
            href="https://drringhealth.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../img/DrringImg.png")}
              alt="Drring Health"
              width="90px"
              height="60px"
              opacity="0px"
            />
          </a>
          <button onClick={onClose} className="text-black hover:text-gray-700">
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <h1 className="text-2xl font-bold mb-4">{title}</h1>

        {ContentComponent && <ContentComponent />}

        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default Modal;