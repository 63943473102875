import { useState, useEffect } from 'react';
import Footer from "./Footer"
import { useNavigate } from "react-router-dom";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { loginRequest, protectedResources } from "../authConfig";
import { useMsal } from '@azure/msal-react';
import { setLocalStorage } from '../cryptoUtils';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import Modal from './Modal';


const SignIn = () => {

  const [modalContent, setModalContent] = useState({ isOpen: false, title: '', ContentComponent: null });

    const openModal = (title, ContentComponent) => {
        setModalContent({ isOpen: true, title, ContentComponent });
    };

    const closeModal = () => {
        setModalContent({ isOpen: false, title: '', ContentComponent: null });
    };

  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.familyAPI.scopes.read,
  });
  
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      const activeAccount = accounts[0];
      const EmailAddress = activeAccount.username;
      execute("GET", `${protectedResources.familyAPI.endpoint}/${EmailAddress}`)
        .then((response) => {
          setUserData(response);
          if (response.isAuthorized === true) {
            setLocalStorage('mobile', response.mobile);
            navigate("/Home");
          } else {
            navigate("/SMSVerification");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isAuthenticated, execute, navigate, accounts]);

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).then(() => {
      const active = instance.getActiveAccount();     
      setLocalStorage('Username', active.username);
    }).catch((error) => console.log(error));
};

  // const handleAppleSignIn = () => {
  //   // Implement Apple sign-in logic
  //   window.location.href = 'https://appleid.apple.com/auth/authorize?...'; // Replace with your Apple OAuth URL
  // };

  return (
    <>
      <div className="flex flex-col justify-between min-h-screen bg-white">
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-white p-8 m-4 w-full max-w-sm">
          <h1 className="text-2xl font-semibold text-center mb-6">Sign in</h1>

          <button
            onClick={handleLoginRedirect}
            className="w-full py-2 mb-4 flex items-center justify-center bg-gray-100  rounded-xl hover:bg-blue-600"
          >
            <img
              src="https://img.icons8.com/color/24/000000/google-logo.png"
              alt="Google logo"
              className="mr-2"
            />
            <span>Continue with Google</span>
          </button>

          {/* <button
          onClick={handleAppleSignIn}
          className="w-full py-2 mb-4 flex items-center justify-center bg-black text-white rounded-xl hover:bg-gray-800">
          <img src="https://img.icons8.com/ios-filled/24/ffffff/mac-os.png" alt="Apple logo" className="mr-2" />
          <span>Continue with Apple</span>
        </button> */}
          <p className="text-center text-gray-600 text-sm mt-6">
            By clicking continue, you agree to our
            <a
              href="#"
              className="text-black no-underline font-semibold pl-1"
              onClick={(e) => {
                e.preventDefault();
                openModal("Terms of Use", TermsOfUse);
              }}
            >
              Terms of Use
            </a>{" "}
            and
            <a
              href="#"
              className="text-black no-underline font-semibold pl-1"
              onClick={(e) => {
                e.preventDefault();
                openModal("Privacy Policy", PrivacyPolicy);
              }}
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        </div>

        <Footer />
      </div>
      <Modal
          isOpen={modalContent.isOpen}
          onClose={closeModal}
          title={modalContent.title}
          ContentComponent={modalContent.ContentComponent}
        />
      </>
  );
}

export default SignIn;



