import React from 'react';
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { removeLocalStorage } from '../cryptoUtils';

const Navbar = ({ toggleSidebar, isSidebarOpen }) => {

  const navigate = useNavigate();
  const { instance } = useMsal();


  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
        account: activeAccount,
    }).then(() => {
      removeLocalStorage('Username');
      removeLocalStorage('mobile');
      navigate("/");
    }).catch((error) => console.log(error));
  };

  return (
    <nav className="bg-white" style={{ borderBottom: "2px solid #E6E6E6" }}>
      <div className="mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <button className={`sm:hidden text-gray-700 focus:outline-none ${isSidebarOpen ? "open" : ""}`} onClick={toggleSidebar}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <div className="flex items-center">
            <a href="/Home">
              <img src={require('../img/DrringFamily.png')} alt="Drring Family" width="80px" height="50px" opacity="0px" />
            </a>
          </div>
          <div>
            <button className="bg-[#5682EF] hover:bg-[#4065D2] text-white font-semibold py-2 px-3 rounded shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition duration-300 ease-in-out"  onClick={handleLogoutRedirect}>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;